import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/news.tsx";
export const pageTitle = "Armeria Newsletter vol. 1";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Mailchimp = makeShortcode("Mailchimp");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "armeria-newsletter-vol-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#armeria-newsletter-vol-1",
        "aria-label": "armeria newsletter vol 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Armeria Newsletter vol. 1`}</h1>
    <p {...{
      "className": "date"
    }}>{`3rd July 2020`}</p>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-devs"
        }}>{`From the devs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-community"
        }}>{`From the community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#we-need-your-comments"
        }}>{`We need your comments`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#good-first-issues"
        }}>{`Good first issues`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#stay-tuned"
        }}>{`Stay tuned`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#thank-you"
        }}>{`Thank you!`}</a></li>
    </ul>
    <img {...{
      "className": "hideOnReducedMotion",
      "src": "https://i.imgur.com/iZf8DeG.gif",
      "loading": "lazy",
      "width": 250,
      "height": 159
    }}></img>
    <p>{`Do you remember we once told you Armeria 1.0.0 will be out around the end of 2019? It's already 50% past
2020, COVID-19 still strays around the city, and we have yet a few issues in our ever-growing backlog for
the next release. Nevertheless, I feel like 1.0.0 is really close this time.
`}<a parentName="p" {...{
        "href": "https://en.wiktionary.org/wiki/as%C3%AD_es_la_vida"
      }}>{`Así es la vida!`}</a>{` 😅 — `}<a parentName="p" {...{
        "href": "https://github.com/trustin"
      }}><strong parentName="a">{`@trustin`}</strong></a></p>
    <h2 {...{
      "id": "from-the-devs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-devs",
        "aria-label": "from the devs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the devs`}</h2>
    <ul>
      <li parentName="ul">{`We now have our own domain, `}<a parentName="li" {...{
          "href": "https://armeria.dev/"
        }}>{`armeria.dev`}</a>{`! 🌟`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/release-notes/0.99.8"
        }}>{`Armeria 0.99.8`}</a>{`, `}<a parentName="li" {...{
          "href": "/release-notes/0.99.7"
        }}>{`0.99.7`}</a>{` and `}<a parentName="li" {...{
          "href": "/release-notes/0.99.6"
        }}>{`0.99.6`}</a>{`
have been released.`}
        <ul parentName="li">
          <li parentName="ul">{`New `}<a parentName="li" {...{
              "href": "type://CircuitBreakerRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRule.html"
            }}>{`type://CircuitBreakerRule`}</a>{` and `}<a parentName="li" {...{
              "href": "type://CircuitBreakerRuleWithContent:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerRuleWithContent.html"
            }}>{`type://CircuitBreakerRuleWithContent`}</a>{` API let you define a complex
circuit breaking policy conveniently.`}</li>
          <li parentName="ul">{`You can now specify the maximum lifespan of server-side connections using
`}<a parentName="li" {...{
              "href": "type://ServerBuilder#maxConnectionAge(Duration):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#maxConnectionAge(java.time.Duration)"
            }}>{`type://ServerBuilder#maxConnectionAge(Duration)`}</a>{`. This is useful when you have to deal with a load
balancer without HTTP/2 support.`}</li>
          <li parentName="ul">{`Service discovery and registration for
`}<a parentName="li" {...{
              "href": "https://curator.apache.org/curator-x-discovery/index.html"
            }}>{`Curator Service Discovery`}</a>{` and
`}<a parentName="li" {...{
              "href": "http://stevenskelton.ca/finagle-serverset-clusters-using-zookeeper/"
            }}>{`Finagle ServerSets`}</a>{`.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://scalapb.github.io/"
            }}>{`ScalaPB`}</a>{` support with
`}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/tree/main/examples/grpc-scala"
            }}>{`a fully working example`}</a>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "from-the-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-community",
        "aria-label": "from the community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the community`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://engineering.linecorp.com/en/blog/reactive-streams-armeria-1/"
        }}>{`Using Reactive Streams with Armeria - Part 1`}</a>{`
and `}<a parentName="li" {...{
          "href": "https://engineering.linecorp.com/en/blog/reactive-streams-armeria-2/"
        }}>{`Part 2`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/ikhoon"
            }}><strong parentName="a">{`@ikhoon`}</strong></a>{` introduces the basic concepts of Reactive Streams, and explains how you can use Reactive Streams
with Armeria, including the details about:`}
            <ul parentName="li">
              <li parentName="ul">{`How to handle backpressure - observer pattern, push method and pull method`}</li>
              <li parentName="ul">{`How Reactive Streams API works - `}<inlineCode parentName="li">{`Publisher`}</inlineCode>{`, `}<inlineCode parentName="li">{`Subscriber`}</inlineCode>{`, and `}<inlineCode parentName="li">{`Subscription`}</inlineCode></li>
              <li parentName="ul">{`How to interoperate between different Reactive Streams impls, such as Reactor and RxJava`}</li>
              <li parentName="ul">{`Cool Armeria features that help you implement a microservice`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sookiwi.com/posts/tech/2019/11/17/LINE-Bot-API-with-Armeria/"
        }}>{`LINE Bot API with Armeria`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/carlsagan21"
            }}><strong parentName="a">{`@carlsagan21`}</strong></a>{` shows how his team uses Armeria for Thrift and gRPC services in an Envoy-based service mesh.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "we-need-your-comments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#we-need-your-comments",
        "aria-label": "we need your comments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We need your comments`}</h2>
    <p>{`Your opinion means a lot to us. Please let us know what you think about these proposals:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2793"
        }}>{`#2793`}</a>{` Which nullness annotation library should we use?
Stick to JSR-305, or use `}<a parentName="li" {...{
          "href": "https://github.com/JetBrains/java-annotations/"
        }}>{`JetBrains Java annotations`}</a>{`?`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2862"
        }}>{`#2862`}</a>{` Should `}<inlineCode parentName="li">{`PooledAggregatedHttp{Request,Response}`}</inlineCode>{` extend `}<a parentName="li" {...{
          "href": "type://SafeCloseable:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/SafeCloseable.html"
        }}>{`type://SafeCloseable`}</a>{`?
What's the best way to handle reference-counted objects and their access?`}</li>
    </ul>
    <h2 {...{
      "id": "good-first-issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#good-first-issues",
        "aria-label": "good first issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Good first issues`}</h2>
    <p>{`Want to contribute but not sure where to start from? Try one of these:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1934"
        }}>{`#1934`}</a>{` `}<inlineCode parentName="li">{`:examples:static-files:test`}</inlineCode>{` fails when a home directory has `}<inlineCode parentName="li">{`index.html`}</inlineCode>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2119"
        }}>{`#2119`}</a>{` Override new `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{` methods in Java 12 for `}<inlineCode parentName="li">{`ContextAwareFuture`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "stay-tuned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#stay-tuned",
        "aria-label": "stay tuned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stay tuned`}</h2>
    <p>{`Sign up for our newsletters to keep up-to-date with new releases and useful tips!`}</p>
    <div style={{
      "maxWidth": "320px",
      "marginTop": "0.5rem"
    }}>
  <Mailchimp mdxType="Mailchimp" />
    </div>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you!`}</h2>
    <ThankYou message={() => <p>Since our last newsletter, we received awesome contributions from the following contributors:</p>} usernames={['amitvc', 'andrey-tpt', 'anuraaga', 'cbornet', 'codefromthecrypt', 'eonezhang', 'gary-lo', 'ghkim3221', 'heowc', 'hexoul', 'hueneburg', 'huydx', 'ikhoon', 'imasahiro', 'jongmin92', 'joonhaeng', 'jrhee17', 'KarboniteKream', 'kojilin', 'matsumana', 'max904-github', 'minwoox', 'okue', 'sokangmin', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      